<template>
    <div id="acrylnextPopup">
        <!-- <a href="https://www.acrylnext.com/" target="_blank" title="ACRYL NEXT"> -->
        <!-- <a href="https://www.youtube.com/playlist?list=PL_JJfl1N58cbsWRvMknYVdpWIZhzkwF5E" target="_blank" title="ACRYL NEXT"> -->
        <!-- <a href="javascript:;" id="popupBody" target="_blank" title="ACRYL"> -->
        <!-- <a href="javascript:;" target="_blank" id="popupBody" title="ACRYL" style="position: relative;"> -->
        <a href="javascript:;" id="popupBody" title="ACRYL" style="position: relative;">
            <!-- <img src="@/assets/images/thanksgiving2.png" alt="ACRYL 추석"> -->
            <!-- <img src="@/assets/images/acryl_next_2022_main-1.png" alt="ACRYL NEXT"> -->
            <!-- <img src="@/assets/images/thank_acryl.png" alt=""> -->
            <!-- <img src="@/assets/images/acryl_newyear.png" alt=""> -->
            <img src="@/assets/images/acryl_12.png" alt="">
            <!-- <img src="@/assets/images/19215_b_t.jpg" alt="ACRYL 추석"> -->
            <!-- <button style="
              position: absolute;
              bottom: 0;
              right: 0;
              margin-right: 20px;
              margin-bottom: 20px;
              border-radius: 100px;
              background-color: rgba(0,0,0, 0.5);
              color: #fff;
              width: 150px;
              border: none;
              height: 50px;
              padding: 0 16px;
          ">사전 등록하기</button> -->
        </a>
        <a href="javascript:;" id="closeBtn" role="button" title="닫기">
            <!-- <img src="@/assets/images/acrylnext_close.png" alt="닫기"> -->
            <!-- <img src="@/assets/images/acryl_close_newyear.png" alt="닫기"> -->
            <img src="@/assets/images/acryl_close_blue.png" alt="닫기">
        </a>
    </div>
</template>


<script>
import '@/assets/bs3/js/bootstrap.js'
import '@/assets/bs3/css/bootstrap.css'
import '@/assets/css/common.css'

export default {
  name: 'alert',
  props: [
    'name'
  ],
  data: () => ({
    isOpen: false
  }),
  computed: {
    bodyText () {
      return this.name + this.$t('qa.body')
    },
  }
}
</script>

<style>
  #acrylnextPopup{
    position:fixed;
    display:none;
    top:50%;
    left:50%;
    -webkit-transform:translate(-50%, -50%);
    transform:translate(-50%, -50%);
    width:500px;
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.2);
    z-index: 1000;
  }
  #acrylnextPopup a{
    display:inline-block;
  }
  #acrylnextPopup img{
    display:inline-block;
    vertical-align:top;width:100%;
  }

  @media screen and (max-width: 720px) {
    #acrylnextPopup{
      width:80%;
    }
  }
</style>