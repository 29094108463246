<template>
  <div id="container">

  <!-- 메인 배너 -->
    <Banner v-if="bannerList.length > 0" :list="bannerList" />
  <!-- //메인 배너 -->

  <!-- 본문 -->
    <div id="contents">
      <section id="main-video">
        <div class="areaBox inner-fixed">
          <div class="header-mgroup">
            <h2 class="title"><p v-html="$t('views.main.content-video')"></p></h2>
            <small><p v-html="$t('views.main.content-video-sub')"></p></small>
          </div>
          <div class="video-wrap">
            <iframe class="introduction-media" v-if="$_gGet_Locale() === 'ko'" src="https://www.youtube.com/embed/g_hvksmmfxA" title="Acryl Introduction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe class="introduction-media" v-if="$_gGet_Locale() === 'en'" src="https://www.youtube.com/embed/ZfrRStbnSv8" title="Acryl Introduction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </section>

      <section id="main-about">
        <div class="areaBox inner-fixed">
          <div class="header-mgroup">
            <h2 class="title"><p v-html="$t('views.main.content01')"></p></h2>
          </div>
          <ul class="clear">
            <li class="acrlyBox">
              <img src="@/assets/images/icon-jonathan-platform.png" alt="Jonathan Platform">
              <title><p v-html="$t('views.main.content02-title')"></p></title>
              <small><p v-html="$t('views.main.content02')"></p></small>
            </li>
            <li class="acrlyBox">
              <img src="@/assets/images/icon-jonathan-service.png" alt="Jonathan Service">
              <title><p v-html="$t('views.main.content03-title')"></p></title>
              <small><p v-html="$t('views.main.content03')"></p></small>
            </li>
          </ul>
        </div>
      </section>

      <section id="main-acryl" :class="`${$_gGet_Locale()}`">
        <div class="areaBox inner-fixed">
          <div class="header-mgroup">
            <h2 class="title"><p v-html="$t('views.main.content05')"></p></h2>
          </div>
          <ul class="owl-carousel owl-acryl clear">
            <li @click="openTo('https://jonathan.acryl.ai/bots')">
              <img src="@/assets/images/icon-chatbot.svg" alt="AI chatbot">
              <!-- <title>JONATHAN<br>BOTS™</title> -->
              <small><p v-html="$t('views.main.content06')"></p></small>
            </li>
            <li @click="openTo('https://jonathan.acryl.ai/flightbase')">
              <img src="@/assets/images/icon-flightbase.svg" alt="flightbase">
              <!-- <title>JONATHAN<br>FLIGHTBASE™</title> -->
              <small><p v-html="$t('views.main.content07')"></p></small>
            </li>
            <li @click="openTo('https://jonathan.acryl.ai/intelligence')">
              <img src="@/assets/images/icon-intelligence.svg" alt="intelligence">
              <!-- <title>JONATHAN<br>INTELLIGENCE™</title> -->
              <small><p v-html="$t('views.main.content08')"></p></small>
            </li>
          </ul>
        </div>
      </section>

      <section id="main-jonathan">
        <div class="areaBox inner-fixed">
          <div class="header-mgroup">
            <h2 class="title"><img id="logo-main-jonathan" src="@/assets/images/logo-jonathan.png" alt="jonathan"></h2>
          </div>
          <small><p v-html="$t('views.main.content09')"></p></small>
          <a @click="openTo('https://jonathan.acryl.ai/')" class="button-go-white">{{ $t('views.main.content22') }}</a>
        </div>
      </section>

      <!-- <section id="main-reason">
        <div class="areaBox inner-fixed">
          <div class="header-mgroup">
            <h2 class="title eng">PERFECT REASON</h2>
            <small>
              <p v-html="$t('views.main.content10')"></p>
            </small>
          </div>
          <ul>
            <li>
              <div class="reasonBox">
                <img src="@/assets/images/image-place.png" alt="">
                <div>
                  <title>PLACE</title>
                  <small><p v-html="$t('views.main.content11')"></p></small>
                </div>
              </div>
              <div class="reasonBox">
                <img src="@/assets/images/image-pathfinder.png" alt="">
                <div>
                  <title>PATHFINDER</title>
                  <small><p v-html="$t('views.main.content12')"></p></small>
                </div>
              </div>
            </li>
            <li>
              <div class="reasonBox">
                <img src="@/assets/images/image-partner.png" alt="">
                <div>
                  <title>PARTNER</title>
                  <small><p v-html="$t('views.main.content13')"></p></small>
                </div>
              </div>
              <div class="reasonBox">
                <img src="@/assets/images/image-paradigm.png" alt="">
                <div>
                  <title>PARADIGM</title>
                  <small><p v-html="$t('views.main.content14')"></p></small>
                </div>
              </div>
            </li>
          </ul>

        </div>
      </section> -->

      <section id="main-news">
        <div class="areaBox inner-fixed">
          <div class="header-mgroup">
            <h2 class="title eng">LATEST NEWS</h2>
            <small><p v-html="$t('views.main.content15')"></p></small>
          </div>
          <ul class="newsWrap clear">
            <li
              v-for="(news, index) in newsList.slice(0, 2)"
              :key="index"
            >
              <div class="newsBox" @click="handleLink('newsView', {'query': {'id': news.id}})">
                <div class="imgBox"><img :src="news.thumbnailImage" alt=""></div>
                <div class="imgInfo">
                  <title>{{news.thumbnailTitle}}</title>
                  <small>{{news.thumbnailInfo}}</small>
                  <a><span>{{ $t('views.main.content16') }}></span> <i class="icon-arrow">></i></a>
                </div>
              </div>
            </li>
            <li>
              <div class="newsBox" @click="handleLink('acryl', {'params': {'scrollTo': '.acrylWrap'}})">
                <div class="imgBox active"><img src="@/assets/images/image-news-3@3x.png" alt=""></div>
                <div class="imgInfo">
                  <title>{{ $t('views.main.content17') }}</title>
                  <small><p v-html="$t('views.main.content18')"></p></small>
                  <a><span>{{ $t('views.main.content16') }}</span> <i class="icon-arrow">></i></a>
                </div>
              </div>
            </li>
          </ul>

        </div>
      </section>

      <section id="main-career">
        <div class="areaBox inner-fixed clear">
          <div class="header-mgroup">
            <h2 class="title eng">
              We are a group<br>
              of Tech-enthusiasts<br>
              full of CURIOSITY
            </h2>
            <small><span><p v-html="$t('views.main.content19')"></p></span></small>
          </div>

          <div class="careerBox"
            v-for="(career, index) in careerList.slice(0, 1)"
            :key="index"
          >
            <title>RECRUITMENT</title>
            <a @click="handleLink('recruitment', {'params': {'careerId': career.id}})"><small>{{career.title}}</small></a>
            <a @click="handleLink('recruitment')">{{ $t('views.main.content20') }} <i class="icon-arrow">></i></a>
          </div>
        </div>
      </section>

      <section id="main-widthAcryl">
        <div class="areaBox inner-fixed">
          <div class="header-mgroup">
            <h2 class="title eng">with ACRYL</h2>
          </div>
          <small><p v-html="$t('views.main.content21')"></p></small>
          <a @click="handleLink('business')" class="button-go-white middle">{{ $t('gnb.business') }}</a>
        </div>
      </section>
    </div>
  <!-- 본문 -->
    <!-- <RecruitPopup></RecruitPopup> -->
    <AcrylnextPopup></AcrylnextPopup>
  </div>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue'
import { owlCarousel, openTo, handleLink } from '@/assets/js/util.js'
import instance from '@/assets/js/api.js'
// import RecruitPopup from '../components/Modal/RecruitModal.vue'
import AcrylnextPopup from '../components/Modal/AcrylnextModal.vue'
import $ from 'jquery'


export default {
  data() {
    return {
      bannerList: [],
      newsList: [],
      careerList: [],
    }
  },
  components: {
    Banner,
    AcrylnextPopup
    // RecruitPopup,
  },
  methods: {
    getBannerList () {
      const url = '/banner/list'
      const method = 'get'

      let data = instance({
        url,
        method,
      })
      data.then(responseData => {
        const {
          result,
          response
        } = responseData
        if (result) {
          const {
            list
          } = response

          this.bannerList = list
        }
      })
      .catch(error => {
      })
    },
    getNewsList () {
      const url = '/news/list'
      const method = 'get'
      const params = { 'maxCount': 2 }

      let data = instance({
        url,
        method,
        params
      })
      data.then(responseData => {
        const {
          result,
          response
        } = responseData
        if (result) {
          const {
            list
          } = response

          this.newsList = list
        }
      })
      .catch(error => {
      })
    },
    getCareerList () {
      const url = '/career/list'
      const method = 'get'
      const params = { 'maxCount': 1 }
      let data = instance({
        url,
        method,
        params
      })
      data.then(responseData => {
        const {
          result,
          response
        } = responseData
        if (result) {
          const {
            list
          } = response

          this.careerList = list
        }
      })
      .catch(error => {
      })
    },
    openTo (path) { openTo(path, true) },
    handleLink (path, query) { handleLink(this.$router, path, query) }
  },
  created: function () {
    this.getBannerList()
    this.getNewsList()
    this.getCareerList()
  },
  mounted: function () {
    let options = {
      loop: false,
      nav: false,
      responsive: {
        0: {items: 1, stagePadding: 40},
        768: {items: 1, stagePadding: 100},
        1200: {items: 3}
      }
    };
    owlCarousel('.owl-acryl', options);
    
    // recruit popup handler
    // var recruitPopup = document.getElementById('recruitPopup'),
    // closeBtn = document.getElementById('closeBtn');

    // recruitPopup.style.display = 'block';
    // closeBtn.addEventListener('click', function(){
    //     recruitPopup.style.display = 'none';
    // });

    // thanksgiving popup handler
    var acrylnextPopup = document.getElementById('acrylnextPopup'),
    closeBtn = document.getElementById('closeBtn'),
    bodyArea = document.getElementById('popupBody');

    // acrylnextPopup.style.display = 'block';
    acrylnextPopup.style.display = 'none';
    closeBtn.addEventListener('click', function(){
        acrylnextPopup.style.display = 'none';
    });

    bodyArea.addEventListener('click', function(){
        acrylnextPopup.style.display = 'none';
        // console.log('즐거운 추석!');
    })

  },
}
</script>
