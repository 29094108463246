<template>
  <div id="banner">
    <section class="visual-main">
      <ul class="owl-carousel owl-visual owl-dot">
        <li 
          class="visual-img"
          v-for="(banner, index) in list"
          :key="index"
          :style="{'background': 'url(' + `${baseUrl + (!isMobile ? banner.imagePC : banner.imageM)}` + ') no-repeat center'}"
        >
          <div class="visual-text inner-fixed">
            <span class="visual-title">
              {{ $t("banner.title", { 'var': $i18n.locale === 'ko' ? $t(banner.title) : $t(banner.titleEnglish)}) }}
            </span>
            <span class="visual-info">
              {{ $t("banner.subTitle", { 'var': $i18n.locale === 'ko' ? $t(banner.subTitle) : $t(banner.subTitleEnglish)}) }}
            </span>
            <a :href="(!isMobile ? banner.linkPC : banner.linkM)" class="button-go" target="_blank">{{ $t("banner.detail") }}</a>
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { owlCarousel } from '@/assets/js/util.js'
import jQuery from 'jquery'

export default {
  props: [
    'list'
  ],
  data() {
    return {
      baseUrl: '',
      isMobile: false
    }
  },
  mounted: function () {
    if (window.innerWidth < 768) { this.isMobile = true; }

    this.baseUrl = process.env.VUE_APP_ACRYL_BASE_URL
    this.$nextTick(function() {
      let options = {
        target: '.owl-visual',
        items: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        loop: true,
      }
      owlCarousel('.owl-visual', options)
    });
  },
  watch: {
    '$i18n.locale' (newValue, oldValue) {
      jQuery(function ($) {
        $('.owl-visual').trigger('refresh.owl.carousel');
      })
    }
  },
}
</script>
